import styled, { css } from 'styled-components';

interface IContainerOptionsProps {
    middle?: boolean;
    enabled?: boolean;
}

export const Container = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    
    justify-content: center;

    padding: 30px;
`;

export const Title = styled.span`
    color: #0e0e0e;
    align-self: center;
    margin-bottom: 10px;
`;

export const ContainerVideo = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;

    padding: 20px;

    align-items: stretch;

    box-shadow: 0px 0px 20px #D6719B;
    border-radius: 50px;

    background-color: rgba(255, 255, 255, 0.85);
`;

export const ContainerContent = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

export const UserVideo = styled.video`
    position: absolute;
    right: 60px;
    bottom: 30px;
    width: 400px;
    height: 400px;
    z-index: 10;

    @media(max-width: 1366px) {
        width: 300px;
        height: 300px;
    }
`;

export const ContainerLoading = styled.div`
    display: flex;
    flex: 1;
    color: #0e0e0e;
    align-items: center;
    justify-content: center;
`;

export const ContainerOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 10px;
`;

export const ButtonOptions = styled.button<IContainerOptionsProps>`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: #114b5f;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    border: none;
    color: #FFF;

    ${(props) =>
        props.middle &&
        css`
            background: #e5446d;
        `
    }

    ${(props) =>
        !props.enabled &&
        css`
            background: #d93025;
        `
    }

    @media(max-width: 550px) {
        width: 35px;
        height: 35px;
        border-radius: 17.5px;
    }
`;