import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { useParams } from 'react-router-dom';
import 'webrtc-adapter';
import io from "socket.io-client";
import Peer from "simple-peer";
import {
    FiVideo,
    FiVideoOff,
    FiPhoneOff,
    FiMic,
    FiMicOff
} from 'react-icons/fi';
import Video from '../../components/Video';
import {
    Container,
    Title,
    ContainerVideo,
    ContainerContent,
    UserVideo,
    ContainerLoading,
    ContainerOptions,
    ButtonOptions
} from './styles';

interface IRouteParams {
    roomID: string;
}

interface IPeerProps {
    userToSignal: string;
    callerID: string;
    stream: MediaStream;
}

interface IPeerRefProps {
    peerID: string;
    peer: Peer.Instance;
}

interface IAddPeerProps {
    userToSignal: Peer.SignalData;
    callerID: string;
    stream: MediaStream;
}

interface IPayloadProps {
    callerID: string;
    id: string;
    signal: Peer.SignalData;
}

const Landing: React.FC = () => {
    const [useVideo, setUseVideo] = useState(true);
    const [useAudio, setUseAudio] = useState(true);
    const [userStream, setUserStream] = useState<MediaStream>();
    const [loadingMessage, setLoadingMessage] = useState('Aguardando conexão...');
    const { roomID } = useParams<IRouteParams>();
    const [peers, setPeers] = useState<Peer.Instance[]>([]);
    const peersRef = useRef<IPeerRefProps[]>([]);
    const userVideo = useRef<HTMLVideoElement>(null);
    const socketRef = useRef<SocketIOClient.Socket>(io.connect('https://apialq.jcpaesf.com'));

    function createPeer({
        userToSignal,
        callerID,
        stream
    }: IPeerProps): Peer.Instance {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
        });

        peer.on("signal", (signal: Peer.SignalData) => {
            socketRef.current.emit("sending signal", { userToSignal, callerID, signal })
        });

        return peer;
    }

    function addPeer({
        userToSignal,
        callerID,
        stream
    }: IAddPeerProps): Peer.Instance {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
            config: {
                iceServers: [{
                    urls: "stun:stun.stunprotocol.org"
                }, {
                    urls: 'turn:numb.viagenie.ca',
                    credential: 'muazkh',
                    username: 'webrtc@live.com'
                }]
            }
        });

        peer.on("signal", (signal: Peer.SignalData) => {
            socketRef.current.emit("returning signal", { signal, callerID })
        });

        peer.signal(userToSignal);

        return peer;
    }

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
            if (userVideo.current) {
                userVideo.current.srcObject = stream;

                setUserStream(stream);
            }

            socketRef.current.emit("join room", roomID);

            socketRef.current.on("all users", (users: string[]) => {
                console.log('opa');
                const peers: Peer.Instance[] = [];

                users.forEach((userID: string) => {
                    const peer = createPeer({
                        userToSignal: userID,
                        callerID: socketRef.current.id,
                        stream
                    });

                    peersRef.current.push({
                        peerID: userID,
                        peer,
                    });

                    peers.push(peer);
                });

                setPeers([...peers]);
            });

            socketRef.current.on("user joined", (payload: IPayloadProps) => {
                setLoadingMessage('Conectando...');

                setTimeout(() => {
                    const peer = addPeer({
                        userToSignal: payload.signal,
                        callerID: payload.callerID,
                        stream
                    });

                    peersRef.current.push({
                        peerID: payload.callerID,
                        peer,
                    });

                    setPeers(users => [...users, peer]);
                }, 2000);
            });

            socketRef.current.on("receiving returned signal", (payload: IPayloadProps) => {
                const item = peersRef.current.find(p => p.peerID === payload.id);

                if (item) {
                    item.peer.signal(payload.signal);
                }
            });
        })
    }, [roomID]);

    const handleEnableVideo = useCallback((enable: boolean) => {
        if (userStream) {
            userStream.getTracks().forEach(stream => {
                if (stream.kind === 'video') {
                    stream.enabled = enable;
                    setUseVideo(enable);
                }
            });
        }

        setUseVideo(enable);
    }, [setUseVideo, userStream]);

    const handleEnableAudio = useCallback((enable: boolean) => {
        if (userStream) {
            userStream.getTracks().forEach(stream => {
                if (stream.kind === 'audio') {
                    stream.enabled = enable;
                    setUseAudio(enable);
                }
            })
        }
    }, [setUseAudio, userStream]);

    return (
        <Container>
            <Title>Atendimento On-line</Title>
            <ContainerVideo>
                <ContainerContent>
                    <UserVideo muted ref={userVideo} autoPlay playsInline />

                    {peers.length ?
                        peers.map((peer, index) => {
                            return (
                                <Video key={index} peer={peer} />
                            );
                        })
                        :
                        <ContainerLoading>
                            {loadingMessage}
                        </ContainerLoading>
                    }
                </ContainerContent>

                <ContainerOptions>
                    <ButtonOptions
                        enabled={useVideo}
                        onClick={() => {
                            handleEnableVideo(!useVideo)
                        }}
                    >
                        {useVideo ? <FiVideo size={22} /> : <FiVideoOff size={22} />}
                    </ButtonOptions>
                    <ButtonOptions middle={true}>
                        <FiPhoneOff size={22} />
                    </ButtonOptions>
                    <ButtonOptions
                        enabled={useAudio}
                        onClick={() => {
                            handleEnableAudio(!useAudio)
                        }}
                    >
                        {useAudio ? <FiMic size={22} /> : <FiMicOff size={22} />}
                    </ButtonOptions>
                </ContainerOptions>
            </ContainerVideo>
        </Container>
    )
}

export default Landing;