import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    body {
        background: linear-gradient(90deg, rgba(171,119,185,1) 0%, rgba(121,9,120,0.8519782913165266) 35%, rgba(143,55,96,0.835171568627451) 100%);
        color: #FFF;
        -webkit-font-smoothing: antialiased;
    }

    body, input, button {
        font-family: 'Roboto slab', serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6, strong, p, span {
        font-family: 'Roboto slab', serif;
    }

    button {
        cursor: pointer;
    }
`