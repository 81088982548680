import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CreateRoom from '../pages/CreateRoom';
import VideoCall from '../pages/VideoCall';

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={CreateRoom} />
            <Route path="/room/:roomID" exact component={VideoCall} />
        </Switch>
    );
}

export default Routes;