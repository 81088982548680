import React, { useEffect, useRef, VideoHTMLAttributes } from 'react';
import { Instance } from "simple-peer";

import { VideoRemote } from './styles';

interface IVideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
    peer: Instance;
}

const Video: React.FC<IVideoProps> = ({ peer, ...rest }) => {
    const refVideo = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        peer.on("stream", (stream: MediaStream) => {
            if (refVideo.current) {
                refVideo.current.srcObject = stream;
            }
        });
    }, [peer]);

    return (
        <VideoRemote
            playsInline
            autoPlay
            ref={refVideo}
            {...rest}
        />
    )
}

export default Video;